body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  padding: 0;
  margin: 0;
  height: 100%;
  color: #666;
  font-family: Avenir, Avenir Next, Montserrat, OpenSans, Helvetica, Arial,
    sans-serif;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  font-size: 18px;
  background: #f7f7f7;
}
